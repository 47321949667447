/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Header from "../Components/Simulation/Header";
import "./Results.scss";
import Person from "../Pictures/person.jpg";
import { FaInfoCircle } from "react-icons/fa";
import Cards from "../Components/Result/Cards";
import { Slider } from "@mui/material";
import { useSelector } from "react-redux";
import {
  ecoImpotsPer,
  getCapitalPerAlone,
  nbrPart,
  plafondDefisc,
  tmi,
} from "../Components/Result/utils/formulas";
import { putSpaces, regexRemoveSpaces } from "../Config/utils";
import Redirect from "../Components/Redirect";
import ModalPhone from "../Components/Result/utils/ModalPhone";
import { AGE_DEPART_RETRAITE, backendUrl } from "../Config/config";
import { prepareDataDB, sendDataDb, updatePhoneChecked } from "../Store/store";
import TagManager from "react-gtm-module";

function sendToken(myPhone) {
  fetch(backendUrl + "simulations/per/2fa/send/index.php", {
    method: "POST",
    body: JSON.stringify({ phone: myPhone }),
    credentials: "include",
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    });
}

function checkToken(
  myPhone,
  myToken,
  dispatch,
  myTimestamp,
  mySessionId,
  updateError,
  userData
) {
  fetch(backendUrl + "simulations/per/2fa/check/index.php", {
    method: "POST",
    body: JSON.stringify({ phone: myPhone, token: myToken }),
    credentials: "include",
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statut == "success") {
        dispatch(updatePhoneChecked("true"));
        dispatch(
          prepareDataDB({
            sessionId: mySessionId,
            timestamp: myTimestamp,
            nameDb: "phone_checked",
            content: "true",
          })
        );
        dispatch(sendDataDb());
        fetch(backendUrl + "simulations/per/sendData/index.php", {
          method: "POST",
          body: JSON.stringify(userData),
          credentials: "include",
          crossDomain: true,
        });
        
        console.log("phoneChecked");
        TagManager.dataLayer({
          dataLayer: {
            event: "phoneChecked",  
          }
        });
          
      } else {
        // Show error message
        updateError(true);
      }
    });
}

export default function Results(props) {
  let phone = useSelector((state) => state.userInput.phone);
  let myEmail = useSelector((state) => state.userInput.email);
  let myCounty = useSelector((state) => state.userInput.county);
  let lastName = useSelector((state) => state.userInput.lastName);
  let myIncome = useSelector((state) => state.userInput.income);
  let myProfessionalActivity = useSelector(
    (state) => state.userInput.professionalActivity
  );
  let myPlafondDefisc = plafondDefisc(
    myProfessionalActivity,
    parseInt(regexRemoveSpaces(myIncome))
  );
  let myAge = useSelector((state) => state.userInput.age);
  let [versement, updateVersement] = useState(myPlafondDefisc);
  let capitalPer = getCapitalPerAlone(versement, 0, parseInt(myAge));
  let myFamilySituation = useSelector(
    (state) => state.userInput.familySituation
  );
  let myNbrKids = useSelector((state) => state.userInput.nbrKids);
  let myNbrParts = nbrPart(myFamilySituation, myNbrKids);
  let myTmi = tmi(parseInt(regexRemoveSpaces(myIncome)), myNbrParts);
  let myEconomieImpots = ecoImpotsPer(
    myProfessionalActivity,
    myTmi,
    parseInt(regexRemoveSpaces(myIncome)),
    versement
  );
  let phoneValidated = useSelector((state) => state.backend.phoneChecked);
  let utmSource = useSelector((state) => state.backend.utmSource);
  let gclid = useSelector((state) => state.backend.gclid);

  let myUserData = {
    familySituation: myFamilySituation,
    age: myAge,
    nbrKids: myNbrKids,
    professionalActivity: myProfessionalActivity,
    income: myIncome,
    lastName: lastName,
    email: myEmail,
    county: myCounty,
    plafondDefisc: myPlafondDefisc,
    capitalPer: capitalPer,
    nbrParts: myNbrParts,
    tmi: myTmi,
    ecoImpots: myEconomieImpots * (AGE_DEPART_RETRAITE - myAge),
    phone: phone,
    utmSource: utmSource,
    gclid: gclid,
  };

  return (
    <div>
      {phoneValidated === "false" ? (
        <ModalPhone
          phone={phone}
          sendSms={sendToken}
          validate={checkToken}
          userData={myUserData}
        />
      ) : (
        ""
      )}
      <Redirect pageIndex={props.pageIndex} />
      <Header showProgressBar={false} />
      <div className="content">
        <div className="welcome">
          <img
            className="pic-conseille"
            src={Person}
            alt="Photo de conseillé"
          />
          <h2 className="welcome-title">Bienvenue M. {lastName} !</h2>
        </div>

        <p >
          Félicitation ! Voici vos résultats vous avez entrepris les démarches
          pour <b>mieux préparer votre retraite</b>
          <br /> <b>L&apos;algorithme de Lexem</b> a calculé votre{" "}
          <b>plan épargne retraite</b> !
        </p>

        <h3 className="result-title">
          Simulation de votre capital disponible à la retraite avec Lexem
        </h3>

        <div className="result-container">
          <Cards
            text="Estimation de votre capital retraite"
            numerous={putSpaces(capitalPer, 3)}
          />
          <Cards
            text="Economie d'impôts potentielle"
            numerous={putSpaces(myEconomieImpots * (AGE_DEPART_RETRAITE - myAge), 3)}
            className="colored"
          />
        </div>

        <div className="result-range">
          <p>Faites varier votre versement en bougeant la réglette 👇</p>
          <Slider
            valueLabelDisplay="on"
            valueLabelFormat={(x) => {
              return x + " €/m";
            }}
            size="medium"
            min={0}
            max={myPlafondDefisc * 4}
            value={versement}
            onChange={(event) => {
              updateVersement(event.target.value);
            }}
          />
        </div>
        <p>
          Votre versement pour une économie d&apos;impôts optimale :{" "}
          <span className="main-color">
            <b>{myPlafondDefisc} €/mois</b>
          </span>
          <span className="plafond-defiscalisation"></span>
        </p>
        <p className="informations-plafond">
          * Les sommes versées au delà du montant pré-affiché seront
          capitalisées pour votre retraite <br /> mais non déductibles (qui dit
          non déductibles dit non imposée à la retraite)
        </p>

        <div className="we-will-call-you">
          {/* Logo */}
          <FaInfoCircle
            fontSize="24px"
            style={{ marginLeft: "10px", marginRight: "10px", flexShrink: "0" }}
          />
          <p>
            Un(e) expert(e) Lexem, vous appellera dans 48h pour effectuer un
            bilan partimonial gratuit.
          </p>
        </div>

        <h4 className="steps-title">
          Les étapes pour réduire vos impôts et améliorer votre retraite
        </h4>

        <div className="steps-container">
          <div className="step 1">
            <div className="first-line">
              <div className="number">1</div>
              <p>Vous avez obtenu vos résultats de simulation</p>
            </div>
            <div className="second-line"></div>
          </div>

          <div className="step 2 colored">
            <div className="first-line">
              <div className="number colored">2</div>
              <p>Profitez d&apos;un bilan patrimonial gratuit</p>
            </div>
            <div className="second-line">
              Un(e) expert(e) Lexem, vous appellera dans 24h pour annalyser
              votre situation gratuitement.
            </div>
          </div>

          <div className="step 3">
            <div className="first-line">
              <div className="number">3</div>
              Choisissez votre montant d&apos;épargne
            </div>
            <div className="second-line">
              Votre conseiller(ère) votre orientera vers le montant le plus
              adapté à vos moyens
            </div>
          </div>

          <div className="step 4">
            <div className="first-line">
              <div className="number">4</div>
              Épargnez pour votre retraite
            </div>
            <div className="second-line"></div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
