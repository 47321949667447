import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./Store/store";
import TagManager from "react-gtm-module";
import "./main.scss";

function App(props) {

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-KXN8NG4J" });
  }, []);

  return <Provider store={store}>{props.component}</Provider>;
}
export default App;
/* eslint react/prop-types: 0 */
